<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="问题" prop="question" >
        <el-input placeholder="请输入问题" v-model="form.question">
        </el-input>
      </el-form-item>
      <el-form-item label="答案" prop="answer" >
        <el-input placeholder="请输入答案" v-model="form.answer">
        </el-input>
      </el-form-item>
      <el-form-item label="是否页面加载展示" prop="isShow" >
         <el-radio-group v-model="form.isShow">
           <el-radio-button :label="false">否</el-radio-button>
           <el-radio-button :label="true">是</el-radio-button>
         </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import {addCustomerMsg,updateCustomerMsg} from '@/api/public.js';
export default {
  props:['row'],
  data() {
    return {
      form:{ // 表单数据
        question:'',
        answer:'',
        isShow:'',
      },
      rules: { // 表单验证
        question: [
          { required: true, message: '问题不得为空', trigger: 'blur', }
        ],
        answer: [
          { required: true, message: '答案不得为空', trigger: 'change', }
        ],
        isShow: [
          { required: true, message: '请选择是否页面加载展示', trigger: 'change', }
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form = this.row ? this.row : this.form;
  },

  methods: {
    // 关闭
    close(){
       this.$emit('close');
    },
    // 新增
    async add(){
      await addCustomerMsg({param:this.form}).then(res=>{
        if(res.success){
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        }
      })
    },
    // 编辑
    async edit(){
      await updateCustomerMsg({param:this.form}).then(res=>{
        if(res.success){
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        }
      })
    },
    // 提交
    submit() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            this.$confirm('确认提交吗？').then(()=>{
              if(this.row){
                this.edit();
              }else{
                this.add();
              }
            })
          } else {
            return false;
          }
      });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
